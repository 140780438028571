<template>
  <el-dialog
    id="FindSKUDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="1366px"
    top="5vh"
    close-on-press-escape
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @closed="onClosed"
    @keydown.enter.native="onFormEnter"
  >
    <el-header class="header2" height="32"
      ><el-row type="flex" justify="start" align="center">
        <TIButton icon="plus_green" @onClick="onAdd" :disabled="!selectedRows" />
        <TIButton icon="delete_red" @onClick="onDel" :disabled="!selectedRows2" />
        <TSeparator />
      </el-row>
    </el-header>
    <el-container v-loading="loading" :element-loading-text="loadingText">
      <el-header class="header3" height="48"
        ><el-row type="flex" justify="start">
          <div>
            <el-input
              :placeholder="$t('Пошук')"
              style="width:308px;"
              prefix-icon="el-icon-search"
              clearable
              v-model="filterString"
              @input="onFilterStringChange"
              ref="filter"
              @keydown.esc.native="resetFilter($event, this)"
            />
          </div>
          <div style="line-height:48px;display:flex;align-items:center;padding-left:16px;">
            <span style="padding-right:8px;">{{ $t("Шукати за") }}</span>
            <el-radio-group v-model="searchBy" size="small">
              <el-radio-button label="name">{{ $t("Назвою") }}</el-radio-button>
              <el-radio-button label="morionId">{{ $t("Morion ID") }}</el-radio-button>
            </el-radio-group>
          </div>
          <div style="line-height:48px;display:flex;align-items:center;padding-left:16px;" v-if="searchBy !== 'morionId'">
            <span style="padding-right:8px;">{{ $t("Каталог") }}</span>
            <el-radio-group v-model="lang" size="small">
              <el-radio-button label="en">{{ $t("EN") }}</el-radio-button>
              <el-radio-button label="ua">{{ $t("UA") }}</el-radio-button>
              <!-- <el-radio-button label="ru">{{ $t("RU") }}</el-radio-button> -->
            </el-radio-group>
          </div>
        </el-row></el-header
      >
      <div ref="flex_container" class="flex_container">
        <splitpanes class="default-theme" horizontal>
          <pane min-size="20" max-size="80">
            <ag-grid-vue
              ref="findSKUTable"
              id="findSKUTable"
              style="width: 100%; height: 100%;"
              class="ag-theme-alpine"
              rowSelection="multiple"
              :defaultColDef="defaultColDef"
              :columnDefs="columns1"
              :rowData="sku"
              :headerHeight="32"
              :gridOptions="gridOptions1"
              @selection-changed="onGridSelectionChanged"
              @rowDoubleClicked="onGridDoubleClick1"
              :overlayNoRowsTemplate="noRowsToShowTemplate"
              @sortChanged="onGridColumnsChange1"
              @columnResized="onGridColumnsChange1"
              @columnMoved="onGridColumnsChange1"
              @displayedColumnsChanged="onGridColumnsChange1"
              @gridReady="onGridReady1"
              :modules="modules"
              :localeTextFunc="gridLocale"
              :enableBrowserTooltips="true"
              :sideBar="sideBar"
              :pivotMode="false"
              :getContextMenuItems="contextMenuItems1"
            >
            </ag-grid-vue>
          </pane>
          <pane min-size="20" max-size="80">
            <ag-grid-vue
              ref="findSKUTable2"
              id="findSKUTable2"
              style="width: 100%; height: 100%;"
              class="ag-theme-alpine"
              rowSelection="multiple"
              :defaultColDef="defaultColDef"
              :columnDefs="columns2"
              :headerHeight="32"
              :rowData="selectedSKU"
              :gridOptions="gridOptions2"
              @selection-changed="onGridSelectionChanged2"
              @rowDoubleClicked="onGridDoubleClick2"
              :overlayNoRowsTemplate="noRowsToShowTemplate"
              @sortChanged="onGridColumnsChange2"
              @columnResized="onGridColumnsChange2"
              @columnMoved="onGridColumnsChange2"
              @displayedColumnsChanged="onGridColumnsChange2"
              @gridReady="onGridReady2"
              :modules="modules"
              :localeTextFunc="gridLocale"
              :sideBar="sideBar"
              :pivotMode="false"
              :getContextMenuItems="contextMenuItems2"
            >
            </ag-grid-vue>
          </pane>
        </splitpanes>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button" :disabled="!selectedSKU || !selectedSKU.length">{{
        $t("Застосувати")
      }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import Icon from "@/components/IconPack";
import TIButton from "@/components/ToolbarIconButton";
import TSeparator from "@/components/ToolbarSeparator";

function parseError(error) {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("; ");
  } else return error;
}

export default {
  name: "FindSKU",
  props: ["busEvent"],
  components: { AgGridVue, Splitpanes, Pane, Icon, TIButton, TSeparator },
  data() {
    var that = this;
    return {
      modules: AllModules,
      dialogShow: false,
      dialogHeight: 0,
      resizeObserver: null,
      title: $tt("Пошук"),
      operation: "",
      target: null,
      model: {},
      initModel: {},
      filterString: "",
      searchBy: "name",
      noRowsToShowTemplate: `<div><img src="${require("../../assets/icons/no_data.svg")}"></div>`,
      selectedRows: null,
      selectedRows2: null,
      selectedSKU: [],
      keyUpTimer: null,
      gridApi1: null,
      gridApi2: null,
      gridColumnApi1: null,
      gridColumnApi2: null,
      lang: "ua",
      gridOptions1: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data.drugsId;
        },
        immutableData: true,
        // getRowStyle: function(params) {
        //   if (that.selectedSKU.find((item) => item.drugsId == params.data.drugsId)) return { "font-weight": "bold" };
        //   else return null;
        // },
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      gridOptions2: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data.drugsId;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
        suppressMenu: false,
        filter: "agSetColumnFilter",
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      sideBar: {
        toolPanels: ["columns", "filters"],
        position: "left",
        hiddenByDefault: false,
      },
      columns1: [
        {
          headerName: $tt("Назва"),
          field: "sku",
          flex: 2,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
        },
        {
          headerName: "Morion ID",
          field: "drugsId",
          width: "200px",
          type: "rightAligned",
        },
        {
          headerName: $tt("Виробник"),
          field: "maker",
          flex: 1,
        },
        {
          headerName: $tt("Маркетуюча організація"),
          field: "marketOrg",
          flex: 1,
        },
        {
          headerName: $tt("Бренд"),
          field: "brand",
          flex: 1,
        },
        {
          headerName: $tt("Рег. дата початок"),
          field: "regDateBegin",
          width: "200px",
          filter: "agDateColumnFilter",
        },
        {
          headerName: $tt("Рег. дата завершення"),
          field: "regDateEnd",
          width: "200px",
          filter: "agDateColumnFilter",
        },
        {
          headerName: $tt("Форма"),
          field: "drugForm",
          flex: 1,
        },
        {
          headerName: $tt("Номер"),
          field: "drugFormNumber",
          width: "100px",
          type: "rightAligned",
        },
      ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
      columns2: [
        {
          headerName: $tt("Назва"),
          field: "sku",
          flex: 2,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
        },
        {
          headerName: "Morion ID",
          field: "drugsId",
          width: "200px",
          type: "rightAligned",
        },
        {
          headerName: $tt("Виробник"),
          field: "maker",
          flex: 1,
        },
        {
          headerName: $tt("Маркетуюча організація"),
          field: "marketOrg",
          flex: 1,
        },
        {
          headerName: $tt("Бренд"),
          field: "brand",
          flex: 1,
        },
        {
          headerName: $tt("Рег. дата початок"),
          field: "regDateBegin",
          width: "200px",
          filter: "agDateColumnFilter",
        },
        {
          headerName: $tt("Рег. дата завершення"),
          field: "regDateEnd",
          width: "200px",
          filter: "agDateColumnFilter",
        },
        {
          headerName: $tt("Форма"),
          field: "drugForm",
          flex: 1,
        },
        {
          headerName: $tt("Номер"),
          field: "drugFormNumber",
          width: "100px",
          type: "rightAligned",
        },
      ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
    };
  },
  mounted() {
    //this.lang = this.$store.getters["main/userSettings"]("findSKULang") || this.$store.getters["main/userSettings"]("userSettings").defaultCatalogLang;
    this.lang = this.$store.getters["main/userSettings"]("userSettings").defaultCatalogLang;
    this.searchBy = this.$store.getters["main/userSettings"]("findSKUsearchBy") || "name";
  },

  created() {
    bus.$on(this.busEvent + ".show", (data) => {
      if (!this.dialogShow) {
        this.title = (data && data.title) || this.title;
        this.initModel = _.extend({}, data ? data.initModel : {});
        this.operation = data ? data.operation : "";
        this.$store.dispatch("sku/init");
        this.filterString = "";
        this.selectedSKU = data && data.selectedSKU ? data.selectedSKU : [];
        this.dialogShow = true;
      }
    });
    bus.$on(this.busEvent + ".hide", () => {
      this.dialogShow = false;
      this.resizeObserver = null;
    });
  },
  computed: {
    sku() {
      return this.$store.getters["sku/store"];
    },
    loading() {
      return this.$store.getters["sku/wait"]();
    },
    loadingText() {
      return this.$store.getters["sku/waitStatus"]();
    },
  },
  watch: {
    dialogHeight(value) {
      var containerHeight = value - 48 - 72 - 48 - 20 - 16 * (this.singleRecord ? 0 : 1);
      // 48 - header 72 - footer 48 - find input 20 - padding 16 - 2 header
      this.$refs.flex_container.style.height = `${containerHeight}px`;
    },
    lang(value) {
      //this.onFilterStringChange(this.filterString, 10);
      this.resetFilter();
      //this.$store.dispatch("main/updateUserSettings", { findSKULang: value });
      this.$store.dispatch("main/updateUserSettings", { userSettings: { defaultCatalogLang: value } });
    },
    searchBy(value) {
      this.resetFilter();
      this.$store.dispatch("main/updateUserSettings", { findSKUSearchBy: value });
    },
  },
  methods: {
    // onGridReady1() {
    //   this.gridApi1 = this.gridOptions1.api;
    //   this.gridColumnApi1 = this.gridOptions1.columnApi;
    //   var state = this.$store.getters["main/userSettings"]("FindSKUGridColumnState1");
    //   if (state) this.gridColumnApi1.setColumnState(state);
    //   this.checkGridSettingsToChanges(this, $tt("Товари"), "FindSKUGridColumnState1", this.columns);
    // }
    onGridReady1() {
      this.restoreGridSettings(this, this.gridOptions1, "FindSKUGrid1", this.columns1, $tt("Товари"));
    },
    //},
    // onGridReady2() {
    //   this.gridApi2 = this.gridOptions2.api;
    //   this.gridColumnApi2 = this.gridOptions2.columnApi;
    //   var state = this.$store.getters["main/userSettings"]("FindSKUGridColumnState2");
    //   if (state) this.gridColumnApi2.setColumnState(state);
    // },
    onGridReady2() {
      this.restoreGridSettings(this, this.gridOptions2, "FindSKUGrid2", this.columns2, $tt("Обрані товари"));
    },
    observeHeight() {
      var that = this;
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(function() {
          that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
        });
        this.resizeObserver.observe(this.$el.children[0]);
      }
    },
    unobserveHeight() {
      this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
      this.resizeObserver = null;
      this.dialogHeight = 0;
    },
    resetFilter(event) {
      if (this.filterString != "") {
        event && event.stopPropagation();
        if (this.loading) {
          this.$store.dispatch("main/cancelAllRequests");
        } else this.$refs.filter.clear();
      }
    },
    onOpen() {
      this.observeHeight();
    },
    onOpened() {
      this.$refs.filter.focus();
    },
    onClose() {
      this.$store.dispatch("main/cancelAllRequests");
      this.dialogShow = false;
    },
    onClosed() {
      this.unobserveHeight();
    },
    onCommit() {
      if (this.selectedSKU && this.selectedSKU.length) {
        this.onClose();
        this.$emit("find-commit", { target: this.target, result: this.selectedSKU, operation: this.operation });
      } else this.$message.error($tt("Будь ласка, додайте один або декілька рядків в нижню таблицю!"));
    },

    onAdd() {
      if (this.selectedRows && this.selectedRows.length > 0) {
        var existIds = this.selectedSKU.map((item) => item.drugsId);
        var newSKU = this.selectedRows.filter((item) => existIds.indexOf(item.drugsId) == -1);
        this.selectedSKU = this.selectedSKU.concat(newSKU);
        this.$store.dispatch("sku/del", newSKU);
      }
    },
    onDel() {
      if (this.selectedRows2 && this.selectedRows2.length > 0) {
        var delIds = this.selectedRows2.map((item) => item.drugsId);
        this.selectedSKU = this.selectedSKU.filter((item) => delIds.indexOf(item.drugsId) == -1);
        this.$store.dispatch("sku/add", this.selectedRows2);
      }
    },
    onFilterStringChange(value, timeout) {
      var that = this;
      if (value == "") this.$store.dispatch("sku/init");
      else {
        if (this.keyUpTimer) clearTimeout(this.keyUpTimer);
        this.keyUpTimer = setTimeout(
          () => {
            that.keyUpTimer = null;
            that.runQueryFilter(value);
          },
          timeout ? timeout : 600
        );
      }
    },
    runQueryFilter(value) {
      if (value && value.length >= 3) {
        this.$store.dispatch("main/cancelAllRequests").then(() => {
          this.$store
            .dispatch("sku/get_list", {
              filter: value,
              lang: this.lang,
              searchBy: this.searchBy,
              outputLang: this.$store.getters["main/userSettings"]("userSettings").defaultCatalogLang,
            })
            .catch((err) => {
              this.$message.error(parseError(err.data.msg));
              this.$store.dispatch("sku/init");
            })
            .finally((data) => {})
            .then((data) => {
              if (data && data.data && data.data.length == 0) {
                this.$message.error($tt("За данним запитом нічого не знайдено!"));
              }
            });
        });
      }
    },
    onGridSelectionChanged() {
      var rows = this.gridOptions1.api.getSelectedRows();
      if (rows && rows.length > 0) {
        this.selectedRows = rows;
      } else {
        this.selectedRows = null;
      }
    },
    onGridSelectionChanged2() {
      var rows = this.gridOptions2.api.getSelectedRows();
      if (rows && rows.length > 0) {
        this.selectedRows2 = rows;
      } else {
        this.selectedRows2 = null;
      }
    },
    onGridDoubleClick() {
      this.onCommit();
    },
    onGridDoubleClick1() {
      this.onAdd();
    },
    onGridDoubleClick2() {
      this.onDel();
    },
    onFormEnter() {
      this.onAdd();
    },
    onGridColumnsChange1() {
      var that = this;
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        clearTimeout(that.changeColumnsTimer);
        this.saveGridState(this.gridOptions1, "FindSKUGrid1");
      }, 500);
    },
    onGridColumnsChange2() {
      var that = this;
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        clearTimeout(that.changeColumnsTimer);
        this.saveGridState(this.gridOptions2, "FindSKUGrid2");
      }, 500);
    },
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
    getContextIcon(icon) {
      return `<img class="svg" src="${require(`../../assets/icons/${icon}.svg`)}" />`;
    },
    contextMenuItems1(params) {
      var contextMenu = [];
      var that = this;
      contextMenu.push(
        {
          name: $tt("Фільтр для кожного стовпчика"),
          shortcut: "Ctrl + F",
          icon: that.getContextIcon("apply_for_column_green"),
          action() {
            that.toggleFloatingFilter(that.gridOptions1, that.columns1);
          },
        },
        "separator"
      );
      contextMenu.push("export");
      return contextMenu;
    },
    contextMenuItems2(params) {
      var contextMenu = [];
      var that = this;
      contextMenu.push(
        {
          name: $tt("Фільтр для кожного стовпчика"),
          shortcut: "Ctrl + F",
          icon: that.getContextIcon("apply_for_column_green"),
          action() {
            that.toggleFloatingFilter(that.gridOptions2, that.columns2);
          },
        },
        "separator"
      );
      contextMenu.push("export");
      return contextMenu;
    },
  },
};
</script>

<style lang="scss" scope>
#FindSKUDialog .header2 {
  background: #fff;
  line-height: 32px;
  border-bottom: 1px solid #ccc !important;
  padding: 0 !important;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
}

#FindSKUDialog.my-dialog .el-dialog--center .el-dialog__body {
  padding: 0px 16px 0 16px !important;
}
</style>
